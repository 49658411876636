@import "../variables/variables";

.adminlist-container {
  margin-top: 30px;
  margin-bottom: 25px;

  .list-head {
    margin-bottom: 20px;

    .adminlist-header {
      display: flex;
      justify-content: space-between;

      .adminlist-filter {
        .status-filter {
          display: flex;
          align-items: center;
          background: #ffffff;
          padding: 10px;
          height: 40px;

          select {
            padding: 10px;
            width: 150px;
            border: none;
            outline: none;
          }
        }

        .category-searchbar {
          .input-group {
            width: 230px;
            box-shadow: 0 2px 8px 0 rgba(#000, 0.14);
            border-radius: 5px;
            color: #06152b;

            .search-product,
            .input-group-text {
              height: 40px;
              border: none;
              font-size: 0.95rem;
              padding-left: 1.25rem;
              background: #ffffff;

              &::placeholder {
                font-size: 0.95rem;
              }
            }
          }
        }
      }
    }

    // .admin-table {
    //   .action-icons {
    //     display: flex;
    //     justify-content: space-around;
    //   }

    //   [dir] td {
    //     padding: 0.72rem 1rem !important;
    //     background-color: var(--bs-table-bg);
    //     border-bottom-width: 1px;
    //     box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    //   }
    // }
  }
}

// Table Custom css
// .eUeqdG {
//   overflow: visible !important;
//   white-space: normal !important;
// }

// .bMgaAx {
//   overflow: visible !important;
//   white-space: normal !important;
// }

// Select box width

.select-custom-w {
  width: 229px;
}

#total_records {
  position: relative;
  top: -40px;
  left: 10px;
}

.pagination {
  margin-top: 25px !important;
  margin-right: 20px !important;
}

@media (min-width: 1400px) {
  .select-custom-w {
    width: 205px !important;
  }
}

.filter-col-gap {
  column-gap: 5px;
}

@media (max-width: 1000px) {
  .col-gap {
    column-gap: 50px;
  }
}

.rw-popup {
  position: absolute;
  right: 70px;
}

.rw-widget-input {
  &::placeholder {
    color: #5e5873 !important;
  }
}

@media (max-width: 1000px) {
  .rw-widget-input {
    width: 130px !important;
  }
}

// @use "react-widgets/styles.scss" with ($input-height: 45px,
// );

.row-gap {
  row-gap: 5px;
}

[dir] .react-dataTable .rdt_TableHead .rdt_TableHeadRow {
  background-color: #f3f2f7;
  border-top: 1px solid;
  border-color: #ebe9f1;
  text-transform: uppercase;
}

.show-limit {
  width: 60px;
  margin-right: 5px;
  margin-left: 5px;
}

@media screen and (max-width: 1400px) {
  .select-custom-w {
    width: 97%;
    margin-bottom: 12px;
  }
}

.add-icon-text {
  vertical-align: super !important;
}

.select_filter {
  height: 48px;
}

//--- No Records Found ---

.admin-table {
  .no-records {
    position: absolute;
    // left: 520px;
    margin-top: 2px;
    background: white;
    padding: 5px;
    width: 95%;
    text-align: center;
    margin-bottom: 50px;
    height: 50px;
  }
}

//------------------ Filter scss --------

.cashier-filter-container {
  margin-bottom: 10px;
}

// --------------- Side

.offcanvas {
  padding: 20px !important;
  border-radius: 0px 0px 0px 0px !important;
  width: 420px !important;

  .gloabl-btn {
    margin-top: 20px !important;
  }
}

[dir] td,
[dir] th {
  padding: 0.72rem 1rem !important;
  background-color: #e1e1e1; //var(--bs-table-bg)
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

[dir] tbody,
[dir] tr {
  background: white !important;
}

/* Overriding element hover */
.optionContainer li:hover,
.optionContainer .highlight {
  background: #4263eb;
  color: #fff;
}

/* Ends overriding */

/* change chips background */
.multiselect-container {
  .search-wrapper {
    span {
      background: #4263eb;
    }
  }
}

/* change chips background */

select {
  option:hover {
    background-color: #4263eb !important;
  }
}

//** Breadcrumb Css

.breadcrumb {
  display: flex !important;
  align-items: center !important;

  .headcrumb {
    font-size: 18px;
    font-weight: 500;
    margin-top: -3px;
  }
}

.react-datepicker__input-container {
  input {
    width: 340px;
    height: 37px;
    outline-color: #a6a3b3 !important;
    color: #6e6b7b;
    padding: 14px;
  }
}

// ** Style for active Tab  */
.active {
  span {
    font-weight: bold !important;
    color: $primary !important;
  }
}

//date required Fiels
.requiredDate {
  position: absolute;
  left: "100px";
  bottom: "2px";
  color: "black";
}

.exportBtn {
  border: 1px solid #2ecc71;
  border-radius: 0.358rem;
  color: #2ecc71;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  margin-right: 10px !important;
  &:focus {
    color: #2ecc71;
    outline: 0;
  }
  &:active {
    color: #2ecc71;
  }
  &:hover {
    box-shadow: 0 0 40px 40px #2ecc71 inset;
    color: #fff;
    outline: 0;
  }
}
